<template>
    <div id="DonateProcessSuccess">
        <section id="step1" v-show="stepController === 1">
            <article class="f14 f12-mb">
                <p>
                    ここで『大親神大生命』に対し奉り、合掌させて頂き <br />
                    前頭葉に意識を集中して、真心を込めて
                    しっかりと念じて下さい。
                    <br />
                    <br />
                    ［念じ方の例］
                    <br />
                    <br />
                    「『大親神大生命』、生命をお与え頂き <br />
                    生かして頂きまして、ありがとうございます」 <br />
                    「わずかではございますが、<br />
                    親感謝の誠をお受け取り下さいますよう<br />
                    慎んでお願い申し上げます」<br />
                    <br />
                    「地球が素晴らしい惑星とならせて頂けますように<br />
                    他の生命が素晴らしく幸せに生きられますように<br />
                    その種となれますようプラスの生き方をさせて頂きます。<br />
                    なにとぞ お願い申し上げます」
                </p>
            </article>
            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="stepController += 1">
                    はい、念じました
                </button>
            </div>
        </section>

        <section id="step2" v-show="stepController === 2">
            <article class="f14 f12-mb">
                <p>「おつなぎ徳積み」が完了しました。</p>
                <p>
                    大自然界より完了メールが送信されますので、ご確認ください。
                </p>
            </article>

            <div class="button-wrap">
                <button class="f18 f16-mb" @click.prevent="returnHome">
                    完了
                </button>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'DonateProcessSuccess',
}
</script>
<script setup>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useCookies } from 'vue3-cookies'
import { updatePaymentStatus, updateDonateStatus } from '@/service/api'
const { cookies } = useCookies()
const route = useRoute()
const router = useRouter()
const store = useStore()

onMounted(async () => {
    await updatePaymentStatus(
        route.query.payment_intent_client_secret,
        route.query.redirect_status
    )
})

const stepController = ref(1)
async function returnHome() {
    const serverRole = store.getters.getServerRole
    if (serverRole === 'guest') {
        router.push({ name: 'Home' })
    } else {
        await updateDonateStatus()
        if (cookies.isKey('backRoutePath')) {
            let backRoutePath = cookies.get('backRoutePath')
            cookies.remove('backRoutePath')
            router.push({ path: backRoutePath })
        } else {
            router.push({ name: 'MemberSystemAccount' })
        }
    }
}
</script>
<style lang="scss" scoped>
article {
    margin: 30px 0;
}
.button-wrap {
    display: flex;
    justify-content: center;
}
button {
    @include solidButtonStyle($majorPurple);
    margin: 50px 10px 0;
    width: 200px;
}
.purple {
    color: $majorPurple;
}
</style>
